<script setup lang="ts">
import { computed } from "vue";

import useDcpState from "..//composables/dcp-state";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;
const { layout, scopeId } = useDcpState();

const notificationServicesUrl = computed(() => {
  return `/foretag/mybusiness/${scopeId.value}/hantera/produkter-tjanster/datanet-vpn/notifieringar`;
});

const pageLoading = computed(() => {
  return layout.value.page.loading;
});

const productsAndServicesUrl = computed(() => {
  return `/foretag/mybusiness/${scopeId.value}/hantera/produkter-tjanster`;
});

const startPageUrl = computed(() => {
  return `/foretag/mybusiness/${scopeId.value}`;
});
</script>

<template>
  <div>
    <template v-if="pageLoading">
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
    </template>
    <template v-else>
      <telia-breadcrumbs ally-label="Breadcrumbs">
        <telia-breadcrumbs-item t-id="link-back">
          <b2x-sspa-link variant="navigation" disable-visited :href="startPageUrl">
            <span slot="right">
              <telia-p>MyBusiness</telia-p>
            </span>
          </b2x-sspa-link>
        </telia-breadcrumbs-item>
        <telia-breadcrumbs-item t-id="link-back">
          <b2x-sspa-link variant="navigation" disable-visited :href="productsAndServicesUrl">
            <span slot="right">
              <telia-p>{{ t("mybusiness.products_and_services") }}</telia-p>
            </span>
          </b2x-sspa-link>
        </telia-breadcrumbs-item>
        <telia-breadcrumbs-item t-id="link-back">
          <b2x-sspa-link variant="navigation" disable-visited :href="notificationServicesUrl">
            <span slot="right">
              <telia-p>{{ t("mybusiness.datacom_header") }}</telia-p>
            </span>
          </b2x-sspa-link>
        </telia-breadcrumbs-item>
        <telia-breadcrumbs-item current class="current-page">
          <telia-p>{{ t("mybusiness.current_page") }}</telia-p></telia-breadcrumbs-item
        >
      </telia-breadcrumbs>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.current-page {
  display: flex;
  align-items: center;
}

telia-skeleton {
  height: 1.6rem;
  width: 32rem;
  margin: 1rem 0;
}
</style>
