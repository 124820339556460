import { computed, ref } from "vue";

import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { getOrganizations, hasPermission } from "@telia/b2b-logged-in-service";

import { fetchNotifications, getContactPerson } from "../services/corp-customer-datacom-messaging";
import { createContactPerson, createLayout, createOrganizations } from "./consts/create_state";
import { logError } from "../utils/log-error";

const layout = ref(createLayout());
const organizations = ref(createOrganizations());
const scopeId = ref("");
const contactPerson = ref(createContactPerson());
const notificationsList = ref<NotificationResponse[]>([]);

const useDcpState = () => {
  const initialize = () => {
    _initializeData();
  };

  const tryToFetchNotificationsList = () => {
    _tryToFetchNotificationsList();
  };

  const tscid = computed(() => organizations.value.selected.tscid);

  return {
    layout,
    scopeId,
    tscid,
    contactPerson,
    notificationsList,
    initialize,
    tryToFetchNotificationsList,
  };
};

export default useDcpState;

const _initializeData = async (): Promise<void> => {
  try {
    await _checkLoggedInUserPermissions();
    if (layout.value.page.showAccessError) return;
    contactPerson.value.personId = _getPersonIdFromUrl();
    await _setUserData();
    await _tryToFetchContactPerson();
    await _tryToFetchNotificationsList();
  } catch (error) {
    layout.value.page.error = true;
  }
};

const _checkLoggedInUserPermissions = async (): Promise<void> => {
  const NOTIFICATIONS_READ_ACCESS = "READ_DATACOM_NOTIFICATIONS";
  const NOTIFICATIONS_WRITE_ACCESS = "WRITE_DATACOM_NOTIFICATIONS";
  try {
    layout.value.page.showAccessError = !(
      (await hasPermission(NOTIFICATIONS_READ_ACCESS)) &&
      (await hasPermission(NOTIFICATIONS_WRITE_ACCESS))
    );
  } catch {
    layout.value.page.error = true;
    logError("Failed to fetch logged in users accesses");
  }
};

const _setUserData = async (): Promise<void> => {
  scopeId.value = await getScopeIdOrThrow();
  const organizationsResponse = await getOrganizations();
  organizations.value.list = organizationsResponse.map((organisation) => ({
    name: organisation.name ?? "",
    tscid: organisation.tscid ?? "",
    number: organisation.organizationNumber ?? "",
  }));
  organizations.value.selected.tscid = organizations.value.list[0].tscid;
};

const _tryToFetchContactPerson = async (): Promise<void> => {
  try {
    const contactPersonResponse = await getContactPerson(
      scopeId.value,
      organizations.value.selected.tscid,
      contactPerson.value.personId
    );
    contactPerson.value.fullName = contactPersonResponse.fullName ?? "";
    contactPerson.value.email = contactPersonResponse.email ?? "";
    contactPerson.value.mobilePhone = contactPersonResponse.mobilePhone ?? "";
    contactPerson.value.organizationNumber = contactPersonResponse.organisationNumber ?? "";

    const orgTscid = organizations.value.list.find(
      (org) => org.number === contactPerson.value.organizationNumber
    )?.tscid;

    organizations.value.selected.tscid = orgTscid ?? "";
  } catch (error) {
    logError("Failed to fetch contact person");
    throw error;
  }
};

const _tryToFetchNotificationsList = async (): Promise<void> => {
  try {
    layout.value.fetchingNotifications.error = false;
    notificationsList.value = await fetchNotifications(
      scopeId.value,
      organizations.value.selected.tscid,
      contactPerson.value.personId
    );
  } catch (error) {
    layout.value.fetchingNotifications.error = true;
    logError("Failed to fetch notifications");
  } finally {
    layout.value.page.loading = false;
  }
};

const _getPersonIdFromUrl = (): number => {
  const pathnameArray = window.location.pathname.split("/");
  const personId = pathnameArray[8];

  return Number(personId);
};
