<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import useEditContactPerson from "../../composables/edit-contact-person";

import { translateMixin } from "../../locale";

import Notification from "./notification.vue";
import contactPersonForm from "./contact-person-form.vue";

onMounted(() => {
  isDrawerOpen.value = true;
});

const isDrawerOpen = ref(false);

const t = translateMixin.methods.t;
const { editContactFormDrawerLayout, closeEditContactPersonDrawer } = useEditContactPerson();

const isOpen = computed(() => {
  return editContactFormDrawerLayout.value.isDrawerOpen && isDrawerOpen;
});

const showErrorNotification = computed(() => {
  return editContactFormDrawerLayout.value.showErrorNotification;
});

const closeDrawer = () => {
  isDrawerOpen.value = false;
  closeEditContactPersonDrawer();
};
</script>

<template>
  <b2x-drawer
    t-id="edit-contact-form-drawer"
    :heading="t('mybusiness.edit_contact_person')"
    position="right"
    :is-open="isOpen"
    @drawerClose="closeDrawer"
  >
    <div>
      <contact-person-form />
      <notification v-if="showErrorNotification" />
    </div>
  </b2x-drawer>
</template>

<style lang="scss" scoped></style>
