import { corpCustomerDatacomMessaging } from "@telia/b2b-rest-client";

export const fetchNotifications = (
  scopeId: string,
  tscid: string,
  personId: number
): Promise<NotificationResponse[]> => {
  return new Promise<NotificationResponse[]>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.listNotifications(
      scopeId,
      tscid,
      personId
    )
      .then((response) => resolve(_mapNotificationsResponse(response)))
      .catch((error) => reject(error));
  });
};

export const deleteNotification = (
  scopeId: string,
  tscid: string,
  notificationId: number
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.deleteNotification(
      scopeId,
      tscid,
      notificationId
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getContactPerson = (
  scopeId: string,
  tscid: string,
  personId: number
): Promise<corpCustomerDatacomMessaging.GetPersonResponseDTO> => {
  return new Promise<corpCustomerDatacomMessaging.GetPersonResponseDTO>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.getPerson(
      scopeId,
      tscid,
      personId
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const updateContactPerson = (
  scopeId: string,
  tscid: string,
  personId: number,
  payload: corpCustomerDatacomMessaging.CreateAndUpdatePersonRequestDTO
): Promise<corpCustomerDatacomMessaging.CreateAndUpdatePersonResponseDTO> => {
  return new Promise<corpCustomerDatacomMessaging.CreateAndUpdatePersonResponseDTO>(
    (resolve, reject) => {
      corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.updatePerson(
        scopeId,
        tscid,
        personId,
        payload
      )
        .then((response) => resolve(response))
        .catch((error) => reject(error.status));
    }
  );
};

const _mapNotificationsResponse = (
  notifications: corpCustomerDatacomMessaging.NotificationsResponseDTO[]
): NotificationResponse[] => {
  return notifications.map((notification) => ({
    triggers: notification.triggers ?? [],
    actions: notification.actions ?? [],
    slas: notification.slas ?? [],
    subscriptions: notification.subscriptions ?? [],
    notificationId: notification.notificationId ?? 0,
  }));
};
