<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";

import useDcpState from "./composables/dcp-state";
import useEditContactPerson from "./composables/edit-contact-person";

import { translateSetup } from "./locale";

import Breadcrumbs from "./components/breadcrumbs.vue";
import ServicesTable from "./components/services-table.vue";
import HeaderDescription from "./components/header-description.vue";
import ErrorNotification from "./components/error-notification.vue";
import NoAccessNotification from "./components/no-access-notification.vue";
import NewNotificationService from "./components/new-notification-service.vue";
import RemoveNotificationServiceModal from "./components/remove-notification-service-modal.vue";
import EditContactFormDrawer from "./components/edit-contact-form-drawer/edit-contact-form-drawer.vue";

onBeforeMount(async () => {
  await translateSetup(["mybusiness"]);
  reRenderKey.value++;
  initialize();
});

const reRenderKey = ref(0);
const removeNotificationId = ref(0);
const openRemoveNotificationModal = ref(false);
const { layout, initialize } = useDcpState();
const { editContactFormDrawerLayout } = useEditContactPerson();

const pageError = computed(() => {
  return layout.value.page.error;
});

const showAccessError = computed(() => {
  return layout.value.page.showAccessError;
});

const showEditContactFormDrawer = computed(() => {
  return editContactFormDrawerLayout.value.isDrawerOpen;
});

const removeNotificationModal = (id: number) => {
  removeNotificationId.value = id;
  openRemoveNotificationModal.value = true;
};

const closeRemoveNotificationModal = () => {
  openRemoveNotificationModal.value = false;
};
</script>

<template>
  <div t-id="b2b-datacom-contact-person" :key="reRenderKey">
    <telia-grid>
      <breadcrumbs v-if="!showAccessError" />
      <error-notification v-if="pageError" />
      <no-access-notification v-else-if="showAccessError" />
      <template v-else>
        <header-description />
        <new-notification-service />
        <services-table @remove-notification-modal="removeNotificationModal" />
      </template>
    </telia-grid>
    <edit-contact-form-drawer v-if="showEditContactFormDrawer" />
    <remove-notification-service-modal
      :id="removeNotificationId"
      :open="openRemoveNotificationModal"
      @close-modal="closeRemoveNotificationModal"
    />
  </div>
</template>

<style lang="scss" scoped></style>
