import { Ref, ref } from "vue";

import { updateContactPerson } from "../services/corp-customer-datacom-messaging";
import { createEditContactPersonDrawerLayout } from "./consts/create_state";

import { logError } from "../utils/log-error";
import {
  trackCloseEditContactPersonDrawer,
  trackOpenEditContactPersonDrawer,
  trackSubmitEditContactPersonFormError,
  trackSubmitEditContactPersonFormInitated,
  trackSubmitEditContactPersonFormSuccessful,
} from "../utils/analytics";

const editContactFormDrawerLayout = ref(createEditContactPersonDrawerLayout());

const useEditContactPerson = () => {
  const openEditContactPersonDrawer = (): void => {
    editContactFormDrawerLayout.value.isDrawerOpen = true;
    trackOpenEditContactPersonDrawer();
  };

  const closeEditContactPersonDrawer = (): void => {
    editContactFormDrawerLayout.value.isDrawerOpen = false;
    editContactFormDrawerLayout.value.showErrorNotification = false;
    editContactFormDrawerLayout.value.contactPersonExistsError = false;

    trackCloseEditContactPersonDrawer();
  };

  const closeDrawerNotification = (): void => {
    editContactFormDrawerLayout.value.showErrorNotification = false;
  };

  const tryToSubmitForm = async (
    newInfo: { firstName: string; lastName: string; email: string; mobilePhone: string },
    contactPerson: Ref<ContactPerson>,
    scopeId: string,
    tscid: string
  ) => {
    try {
      editContactFormDrawerLayout.value.submitting = true;
      editContactFormDrawerLayout.value.showErrorNotification = false;
      editContactFormDrawerLayout.value.contactPersonExistsError = false;

      trackSubmitEditContactPersonFormInitated();

      const payload = {
        firstName: newInfo.firstName,
        lastName: newInfo.lastName,
        email: newInfo.email,
        mobilePhone: newInfo.mobilePhone,
      };
      await updateContactPerson(scopeId, tscid, contactPerson.value.personId, payload);

      contactPerson.value.fullName = `${payload.firstName} ${payload.lastName}`;
      contactPerson.value.email = payload.email;
      contactPerson.value.mobilePhone = payload.mobilePhone;

      trackSubmitEditContactPersonFormSuccessful();
      editContactFormDrawerLayout.value.isDrawerOpen = false;
    } catch (errorStatus) {
      editContactFormDrawerLayout.value.showErrorNotification = true;
      (errorStatus as number) === 409 ? _handleContactPersonExistsError() : _handleError();

      trackSubmitEditContactPersonFormError();
    } finally {
      editContactFormDrawerLayout.value.submitting = false;
    }
  };

  const _handleError = (): void => {
    logError("Could not submit form");
  };

  const _handleContactPersonExistsError = (): void => {
    editContactFormDrawerLayout.value.contactPersonExistsError = true;
    logError("Contact person already exists");
  };

  return {
    editContactFormDrawerLayout,
    openEditContactPersonDrawer,
    closeEditContactPersonDrawer,
    closeDrawerNotification,
    tryToSubmitForm,
  };
};

export default useEditContactPerson;
