<script setup lang="ts">
import { computed } from "vue";

import useDcpState from "..//composables/dcp-state";

import { translateMixin } from "../locale";
import ContactPersonInfo from "./contact-person-info.vue";

const t = translateMixin.methods.t;
const { layout, contactPerson } = useDcpState();

const organizationNumber = computed(() => {
  return `${t("mybusiness.invoice.organisation.number")}: ${
    contactPerson.value.organizationNumber
  }`;
});

const contactPersonName = computed(() => {
  return contactPerson.value.fullName.toUpperCase();
});

const isLoading = computed(() => {
  return layout.value.page.loading;
});
</script>

<template>
  <section class="datacom-contact-person__header">
    <template v-if="isLoading">
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
    </template>
    <template v-else>
      <telia-heading t-id="page-header" tag="h1" variant="title-200">{{
        contactPersonName
      }}</telia-heading>
      <telia-p t-id="user-organization-number">{{ organizationNumber }}</telia-p>
      <contact-person-info />
    </template>
  </section>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";
.datacom-contact-person__header {
  margin: $telia-spacing-32 0;
  border-bottom: 3px solid $telia-gray-100;

  telia-p {
    color: $telia-gray-700;
  }

  telia-skeleton {
    height: 4.7rem;
    width: 22rem;
  }

  telia-skeleton:not(:first-child) {
    height: 1.6rem;
    margin: 1rem 0;
  }
}
</style>
