import { action, category, label, trackEvent } from "@telia/b2b-web-analytics-wrapper";

const ANALYTICS = {
  CATEGORIES: {
    CATEGORY: "Datacom_Contact_Person",
    EDIT_CONTACT_PERSON_DRAWER: "Edit_Contact_Person_Drawer",
  },
  ACTION: {
    CLOSE_MODAL: "CloseModal",
  },
  LABELS: {
    EDIT_CONTACT_PERSON_DRAWER: "Edit contact person drawer",
    SUBMIT_EDIT_CONTACT_FORM: "Submit edit contact form",
    REMOVE_NOTIFICATION_SERVICEL: "Remove notification service",
  },
};

const analytics = {
  action,
  category,
  label,
};

export const tablePaginationChangeGoogleAnalytics = (
  detail: { page: number; pageSize: number },
  pageSizeHasChanged: boolean
): void => {
  const label = pageSizeHasChanged
    ? `Page size selected ${detail.pageSize}`
    : `Page selected ${detail.page}`;
  _trackEvent(label, analytics.action.CLICK);
};

export const trackCloseRemoveServiceModal = () => {
  _trackEvent(ANALYTICS.LABELS.REMOVE_NOTIFICATION_SERVICEL, ANALYTICS.ACTION.CLOSE_MODAL);
};

export const trackOpenRemoveNotificationServiceModal = () => {
  _trackEvent(ANALYTICS.LABELS.REMOVE_NOTIFICATION_SERVICEL, analytics.action.OPEN_MODAL);
};

export const trackInitatedRemoveNotificationService = () => {
  _trackEvent(ANALYTICS.LABELS.REMOVE_NOTIFICATION_SERVICEL, analytics.action.INITIATED);
};

export const trackCompletedRemoveNotificationService = () => {
  _trackEvent(ANALYTICS.LABELS.REMOVE_NOTIFICATION_SERVICEL, analytics.action.COMPLETED);
};

export const trackErrorRemoveNotificationService = () => {
  _trackEvent(ANALYTICS.LABELS.REMOVE_NOTIFICATION_SERVICEL, analytics.action.ERROR);
};

export const trackOpenEditContactPersonDrawer = () => {
  _trackEvent(ANALYTICS.LABELS.EDIT_CONTACT_PERSON_DRAWER, analytics.action.OPEN);
};

export const trackCloseEditContactPersonDrawer = () => {
  _trackEditContactPersonCategory(analytics.label.CLOSED, analytics.action.CLICK);
};

export const trackSubmitEditContactPersonFormInitated = () => {
  _trackEditContactPersonCategory(
    ANALYTICS.LABELS.SUBMIT_EDIT_CONTACT_FORM,
    analytics.action.INITIATED
  );
};

export const trackSubmitEditContactPersonFormSuccessful = () => {
  _trackEditContactPersonCategory(
    ANALYTICS.LABELS.SUBMIT_EDIT_CONTACT_FORM,
    analytics.action.COMPLETED
  );
};

export const trackSubmitEditContactPersonFormError = () => {
  _trackEditContactPersonCategory(
    ANALYTICS.LABELS.SUBMIT_EDIT_CONTACT_FORM,
    analytics.action.ERROR
  );
};

const _trackEditContactPersonCategory = (label: string, action: string) => {
  _trackEvent(label, action, ANALYTICS.CATEGORIES.EDIT_CONTACT_PERSON_DRAWER);
};

const _trackEvent = (
  label: string,
  action: string,
  category: string = ANALYTICS.CATEGORIES.CATEGORY
): void => {
  trackEvent(category, action, label);
};
