export const createContactPerson = (): ContactPerson => ({
  personId: 0,
  fullName: "",
  email: "",
  mobilePhone: "",
  organizationNumber: "",
});

export const createOrganizations = (): Organizations => ({
  selected: {
    name: "",
    number: "",
    tscid: "",
  },
  list: [],
});

export const createLayout = (): Layout => ({
  page: {
    loading: true,
    error: false,
    showAccessError: false,
  },
  fetchingNotifications: {
    error: false,
  },
});

export const createEditContactPersonDrawerLayout = (): EditContactPersonDrawerLayout => ({
  isDrawerOpen: false,
  submitting: false,
  showErrorNotification: false,
  contactPersonExistsError: false,
});
