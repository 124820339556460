export const triggerTranslationsKeys = {
  PLANNED_MAINTENANCE: "mybusiness.datacom_notifications_trigger_planned_maintenance",
  SUBSCRIPTION_IN_TRAFFIC: "mybusiness.datacom_notifications_trigger_subscription_in_traffic",
  TICKET_CREATED_AM03_CO: "mybusiness.datacom_notifications_trigger_ticket_created_am03_co",
  TICKET_CREATED_AM03_DS: "mybusiness.datacom_notifications_trigger_ticket_created_amo3_ds",
  TICKET_EVENT_CLOSED: "mybusiness.ticket_closed",
  TICKET_EVENT_CREATED: "mybusiness.ticket_created",
};

export const actionTranslationsKeys = {
  SEND_EMAIL: "mybusiness.email",
  SEND_SMS: "mybusiness.sms",
};
