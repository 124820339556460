<script setup lang="ts">
import { computed, ref, watch } from "vue";

import useDcpState from "../composables/dcp-state";

import { translateMixin } from "../locale";

import {
  triggerTranslationsKeys,
  actionTranslationsKeys,
} from "../composables/consts/translation_keys";

import { tablePaginationChangeGoogleAnalytics } from "../utils/analytics";

const emit = defineEmits<{ (e: "removeNotificationModal", id: number): void }>();

const t = translateMixin.methods.t;
const tableData = ref<TableRow[]>([]);
const pagination = ref({
  pagesize: 10,
  page: 1,
});

const { layout, notificationsList, contactPerson, scopeId } = useDcpState();

const isLoading = computed(() => {
  return layout.value.page.loading;
});

const hasError = computed(() => {
  return layout.value.fetchingNotifications.error;
});

const message = computed(() => {
  if (hasError.value) {
    return t("mybusiness.datacom_failed_to_fetch_service_list");
  } else if (listLength.value === 0 && !isLoading.value) {
    return t("mybusiness.datacom_fetch_subscriptions_list_empty");
  } else {
    return "";
  }
});

const tableHeaders = computed(() => {
  return [
    { title: t("mybusiness.id"), type: "sspa-link" },
    { title: t("mybusiness.following_events"), type: "truncatable-text" },
    { title: t("mybusiness.send_notice_to"), type: "truncatable-text" },
    { title: t("mybusiness.monitored_services"), type: "truncatable-text" },
    { title: t("mybusiness.remove_notification_services"), type: "button" },
  ];
});

const listLength = computed(() => {
  return notificationsList.value.length;
});

const pagesize = computed(() => {
  return pagination.value.pagesize;
});

const pagesizes = computed(() => {
  return [10, 25, 50];
});

const showPaginator = computed(() => {
  return listLength.value > pagesizes.value[0];
});

const handlePaginationChange = (event: CustomEvent) => {
  tablePaginationChangeGoogleAnalytics(event.detail, pageSizeHasChanged(event.detail.pageSize));
  changePage(event.detail.page, event.detail.pageSize);
};

const handleTableButtonClicked = (event: CustomEvent) => {
  const id = tableData.value[event.detail.rowNumber][0].content;
  emit("removeNotificationModal", id);
};

const generateTabledata = (): void => {
  const offset = getOffset();
  tableData.value = notificationsList.value
    .filter((_item, index) => offset <= index && index < pagination.value.pagesize + offset)
    .map((notification: any, index): TableRow => {
      return [
        createNotificationIdLink(notification.notificationId),
        createTriggerString(notification.triggers),
        createActionsString(notification.actions),
        createMonitoredServicesString(
          notification.subscriptions.length > 0 ? notification.subscriptions : notification.slas
        ),
        setupRemoveServiceButton(index),
      ];
    });
};

const getOffset = (): number => {
  return pagination.value.pagesize * pagination.value.page - pagination.value.pagesize;
};

const createNotificationIdLink = (id: number): NotificationLinkColumn => {
  const personId = contactPerson.value.personId;
  const url = `/foretag/mybusiness/${scopeId.value}/hantera/produkter-tjanster/datanet-vpn/notifieringar/${personId}/${id}`;
  return {
    href: url,
    content: id,
    disableVisited: true,
  };
};

const createTriggerString = (triggers: string[]): string => {
  const triggersStringArray: string[] = triggers.map((trigger) =>
    t(triggerTranslationsKeys[trigger])
  );

  return triggersStringArray.join(", ");
};

const createActionsString = (actions: string[]): string => {
  const actionsStringArray: string[] = actions.map((action) => {
    const translatedString = t(actionTranslationsKeys[action]);
    if (action === "SEND_SMS") return translatedString.toUpperCase();
    return translatedString;
  });

  return actionsStringArray.join(" + ");
};

const createMonitoredServicesString = (services: string[]): string => {
  return services.join(", ");
};

const setupRemoveServiceButton = (index: number): TableButton => {
  return {
    rowNumber: index,
    attrs: {
      type: "button",
      text: t("mybusiness.remove"),
    },
  };
};

const changePage = (pageNumber: number, pageSize: number): void => {
  pagination.value.page = pageNumber;
  pagination.value.pagesize = pageSize;
  generateTabledata();
};

const pageSizeHasChanged = (newpageSize: number): boolean => {
  return newpageSize !== pagination.value.pagesize;
};

watch(
  () => notificationsList.value,
  () => generateTabledata()
);
</script>

<template>
  <section t-id="services-table-wrapper">
    <b2x-table
      :is-loading="isLoading"
      :columns="JSON.stringify(tableHeaders)"
      :data="JSON.stringify(tableData)"
      :message="message"
      @buttonClicked="handleTableButtonClicked"
    />
    <b2x-paginator
      v-if="showPaginator"
      :list-length="listLength"
      :page-sizes="JSON.stringify(pagesizes)"
      :default-page-size="pagesize"
      @paginationChange="handlePaginationChange"
    />
  </section>
</template>

<style lang="scss" scoped></style>
