<script setup lang="ts">
import { computed } from "vue";

import useDcpState from "../../composables/dcp-state";

import { translateMixin } from "../../locale";
import useEditContactPerson from "../../composables/edit-contact-person";

const nameMaxLength = 30;
const nameMinLength = 2;
const nameRegexPattern = "^[a-zA-Z0-9åÅäÄöÖ]* ?[a-zA-Z0-9åÅäÄöÖ]*$";
const t = translateMixin.methods.t;
const { contactPerson, scopeId, tscid } = useDcpState();
const { editContactFormDrawerLayout, closeEditContactPersonDrawer, tryToSubmitForm } =
  useEditContactPerson();

const isSubmitting = computed(() => {
  return editContactFormDrawerLayout.value.submitting;
});

const submit = ({ target }) => {
  const firstName = target.firstName.value;
  const lastName = target.lastName.value;
  const email = target.email.value;
  const mobilePhone = target.mobilePhone.value;
  tryToSubmitForm(
    { firstName, lastName, email, mobilePhone },
    contactPerson,
    scopeId.value,
    tscid.value
  );
};

const getFirstName = (fullName: string) => {
  return fullName.split(" ")[0];
};

const getLastName = (fullName: string) => {
  return fullName.split(" ")[1];
};

const nameMinlengthErrorMessage = () => {
  return t("drawer.form.minLength", { minLength: nameMinLength });
};
</script>

<template>
  <div class="form--wrapper">
    <telia-form t-id="edit-contact-person-form" @submit.prevent="submit">
      <telia-text-input
        :label="t('mybusiness.first_name')"
        type="text"
        inputmode="text"
        name="firstName"
        :value="getFirstName(contactPerson.fullName)"
        required
        :required-error-message="t('mybusiness.serviceRequestForm.fieldRequired')"
        :minlength="nameMinLength"
        :minlength-error-message="nameMinlengthErrorMessage"
        :maxlength="nameMaxLength"
        :pattern="nameRegexPattern"
        :pattern-error-message="t('drawer.form.patternErrorMessage')"
      ></telia-text-input>
      <telia-text-input
        :label="t('mybusiness.surname')"
        type="text"
        inputmode="text"
        name="lastName"
        :value="getLastName(contactPerson.fullName)"
        required
        :required-error-message="t('mybusiness.serviceRequestForm.fieldRequired')"
        :minlength="nameMinLength"
        :minlength-error-message="nameMinlengthErrorMessage"
        :maxlength="nameMaxLength"
        :pattern="nameRegexPattern"
        :pattern-error-message="t('drawer.form.patternErrorMessage')"
      ></telia-text-input>
      <telia-text-input
        :label="t('mybusiness.email')"
        type="email"
        inputmode="email"
        name="email"
        :value="contactPerson.email"
        required
        :required-error-message="t('mybusiness.serviceRequestForm.fieldRequired')"
        :email-error-message="t('drawer.form.invalidEmailErrorMessage')"
      ></telia-text-input>
      <telia-text-input
        :label="t('mybusiness.mobile_phone')"
        type="tel"
        inputmode="tel"
        name="mobilePhone"
        :value="contactPerson.mobilePhone"
      ></telia-text-input>
      <div class="button-wrapper">
        <telia-button
          :text="t('mybusiness.cancel')"
          variant="secondary"
          type="button"
          t-id="cancel-button"
          :disabled="isSubmitting"
          @click="closeEditContactPersonDrawer"
        ></telia-button>
        <b2x-loading-button
          t-id="submit-button"
          variant="primary"
          type="submit"
          :loading-label="t('drawer.form.buttons.submitting')"
          :is-loading="isSubmitting"
          >{{ t("drawer.form.buttons.submit") }}</b2x-loading-button
        >
      </div>
    </telia-form>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

.form--wrapper {
  background-color: $telia-gray-50;
  padding: $telia-spacing-24;
}

div.button-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: $telia-spacing-8;
}
</style>
