<script setup lang="ts">
import { computed } from "vue";

import useEditContactPerson from "../../composables/edit-contact-person";

import { translateMixin } from "../../locale";

const t = translateMixin.methods.t;
const { editContactFormDrawerLayout, closeDrawerNotification } = useEditContactPerson();

const contactPersonExistsError = computed(() => {
  return editContactFormDrawerLayout.value.contactPersonExistsError;
});

const headingText = computed(() => {
  return contactPersonExistsError.value
    ? t("drawer.contactPersonExistsError.header")
    : t("mybusiness.error.heading");
});

const messageText = computed(() => {
  return contactPersonExistsError.value
    ? t("drawer.contactPersonExistsError.messageLine1")
    : t("drawer.error.message");
});
</script>

<template>
  <section>
    <telia-notification
      status="error"
      heading-tag="h2"
      :button-text="t('mybusiness.close')"
      @vocaClose="closeDrawerNotification"
    >
      <span slot="heading">{{ headingText }}</span>
      <telia-text-spacing slot="content">
        <telia-p> {{ messageText }}</telia-p>
        <telia-p v-if="contactPersonExistsError">
          {{ t("drawer.contactPersonExistsError.messageLine2") }}</telia-p
        >
      </telia-text-spacing>
    </telia-notification>
  </section>
</template>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/variables.scss";
@import "@teliads/components/foundations/breakpoints/variables.scss";

section {
  width: 100%;
  margin-top: $telia-spacing-8;
}
</style>
