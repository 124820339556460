<script setup lang="ts">
import useDcpState from "..//composables/dcp-state";
import useEditContactPerson from "../composables/edit-contact-person";

import { translateMixin } from "../locale";

const t = translateMixin.methods.t;

const { contactPerson } = useDcpState();
const { openEditContactPersonDrawer } = useEditContactPerson();
</script>

<template>
  <div>
    <telia-button
      :text="t('mybusiness.datacom_contact_details_notices')"
      variant="text"
      type="button"
      :right-icon="JSON.stringify({ name: 'edit', size: 'sm' })"
      t-id="edit-contact-info-button"
      @click="openEditContactPersonDrawer"
      class="edit-contact-info-button"
    ></telia-button>
    <div class="contact-person-info--container">
      <telia-p t-id="user-name">{{ contactPerson.fullName }}</telia-p>
      <telia-p t-id="user-email">{{ contactPerson.email }}</telia-p>
      <telia-p t-id="user-mobile-number">{{ contactPerson.mobilePhone }}</telia-p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

.contact-person-info--container {
  margin-bottom: $telia-spacing-20;
}

.edit-contact-info-button :deep(.telia-icon__container) {
  color: $telia-black;
}
</style>
