<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../locale";

import useDcpState from "..//composables/dcp-state";

const t = translateMixin.methods.t;
const { layout, contactPerson, scopeId } = useDcpState();

const newMessageServiceUrl = computed(() => {
  const personId = contactPerson.value.personId;
  return `/foretag/mybusiness/${scopeId.value}/hantera/produkter-tjanster/datanet-vpn/notifieringar/${personId}/ny-overvakning`;
});

const isLoading = computed(() => {
  return layout.value.page.loading;
});
</script>

<template>
  <div t-id="new-notification-service-link" class="link--wrapper">
    <template v-if="isLoading">
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
    </template>
    <template v-else>
      <b2x-sspa-link variant="standalone" disable-visited :href="newMessageServiceUrl">
        <div class="link--alignment">
          <telia-icon name="plus" slot="left" size="sm" />
          {{ t("mybusiness.datacom_new_notification_service") }}
        </div>
      </b2x-sspa-link>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

.link--wrapper {
  margin: $telia-spacing-32 0;
  width: 20rem;

  .link--alignment {
    display: flex;
    align-items: center;
  }

  telia-skeleton {
    height: 1.6rem;
    width: 22rem;
  }
}
</style>
